.pill {
  border-radius: 10px;
  border-style: solid;
  border-width: 1px;
  font-size: 12px;
  padding: 2px 8px;

  &.pill-danger {
    border-color: $red700;
    color: $red900;
  }

  &.pill-success {
    border-color: $green700;
    color: $green900;
  }

  &.pill-warning {
    border-color: $yellow;
    color: #704f00;
  }
}