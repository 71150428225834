.mysynergie-favorites {
  position: relative;

  .list-controls {
    min-width: 100%;

    @include media-breakpoint-up(md) {
      min-width: auto;
    }

    button,
    select {
      width: 100%;
      @include media-breakpoint-up(md) {
        width: auto;
      }
    }
  }

  .pager-text {
    font-size: 16px;
  }

  .badge {
    color: white;
    padding: 7px 9px 7px 9px;
  }

  .button-mysynergie-secondary.active {
    border: 2px solid $blue;
  }

  #selection-view {
    bottom: 0;
    margin-left: auto;
    margin-right: auto;
    max-width: 900px;
    position: sticky;
    z-index: 1000;

    #selection-view-wrapper {
      &.show {
        position: fixed;
        z-index: 1000;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        @include media-breakpoint-up(md) {
          height: 100%;
          position: relative;
          top: auto;
          bottom: auto;
          left: auto;
          right: auto;
        }
      }

      #selection-view-header {
        background-color: $blue;
        border-radius: 0px;
        position: sticky;
        top: 0;
        z-index: 1100;
      }

      #selection-details {
        max-height: 0vh;
        overflow: auto;
        position: relative;
        transition: none;
        z-index: 1000;

        &.show {
          max-height: 100vh;
          height: 100%;
          padding-bottom: 160px;

          @include media-breakpoint-up(md) {
            transition: all 0.3s ease-in-out;
            max-height: 70vh;
            height: auto;
            padding-bottom: 0;
          }
        }
      }

      #selection-actions {
        height: 0px;

        &.show {
          height: 150px;
          bottom: 0;
          left: 0;
          right: 0;
          position: fixed;

          @include media-breakpoint-up(sm) {
            height: auto;
          }

          @include media-breakpoint-up(md) {
            position: sticky;
          }
        }
      }
    }
  }

  .backgroundBlur {
    background-color: rgba(
      33,
      37,
      41,
      0.55
    ) !important;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;

    &.show {
      display: block;
    }
  }

  #applicationForm {
    border: none;

    .cta {
      border-radius: 5px;
      font-weight: 600px;
      letter-spacing: 1.25px;
    }
  }
}
