.mySynergie {
  font-size: 14px;
  letter-spacing: 0.1px;

  h1 {
    font-family: "Montserrat", sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0.25px;
    text-transform: none;

    @include media-breakpoint-up(md) {
      font-size: 34px;
    }
  }

  h2 {
    font-family: "Montserrat", sans-serif;
    font-size: 20px;
    font-weight: 500;
    text-transform: none;
  }

  h3 {
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-weight: 600;
    text-transform: none;
  }

  h4 {
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
  }

  h5 {
    font-family: "Montserrat", sans-serif;
    font-size: 20px;
    font-weight: 600;
  }

  a {
    color: inherit;
    text-decoration: none;
  }
}
