

.navbar-avatar {
  border: 2px solid $accent;
  border-radius: 50%;
  color: rgb(var(--bs-secondary-rgb));
  font-family: "Montserrat", sans-serif;
  font-size: 11px;
  position: relative;
  height: 32px;
  width: 32px;

  @keyframes rotate {
    100% {
      transform: rotate(1turn);
    }
  }

  &.header {
    font-size: 24px;
    height: 80px;
    width: 80px;
  }

  &.spinner {
    overflow: hidden;
    &::before {
      content: '';
      position: absolute;
      left: 0px;
      top: 0px;
      width: 100%;
      height: 100%;
      background-color: #399953;
      background-repeat: no-repeat;
      background-size: 50% 50%, 50% 50%;
      background-position: 0 0, 100% 0, 100% 100%, 0 100%;
      background-image: linear-gradient(#E2001a, #E2001a), linear-gradient($gray500, $gray500), linear-gradient($gray500, $gray500),linear-gradient($gray500, $gray500);
      animation: rotate 4s linear infinite;
    }

    &::after {
      content: '';
      position: absolute;
      left: 6px;
      top: 6px;
      width: calc(100% - 12px);
      height: calc(100% - 12px);
      background: white;
      border-radius: 50%;
      animation: opacityChange 3s infinite alternate;
    }
  }
}

.navbar-avatar-inner {
  color: $gray900;
  border-radius: 50%;
  background-color: transparent;
  display: flex;
  height: 28px;
  min-width: 28px;
  position: relative;
  justify-content: center;
  align-items: center;
  text-align: center;
  vertical-align: middle;
  z-index: 50;

  .profilePicture {
    border-radius: 50%;
  }

  &.header {
    height: 72px;
    width: 72px;
  }

  .camera-icon {
    padding: 6px 6px;
    background-color: $gray800;
    border-radius: 50%;
    bottom: -10px;
    color: $gray100;
    height: 32px;
    position: absolute;
    right: -2px;
    width: 32px;
  }

  .notifications-navbar-badge {
    height: 16px;
    width: auto;
    min-width: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 0.25px;
    top: -8px;
    position: absolute;
    right: -6px;
  }
}

.profileAlert {
  position: absolute;
  top: 96px;
  z-index: 1999;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  height: auto;
  border-radius: 25px;
  padding: 4px 8px;
  white-space: nowrap;
  @include media-breakpoint-up(md) {
    right: 0px;
  }
}
