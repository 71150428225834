#myFirstJobPage {
  .titleRow .titleFirstJob {
    font-weight: 600;
  }

  .greyRow {
    background-color: #f8f9fa;
    @include media-breakpoint-down(sm) {
      .percentage {
        height: 104px;
      }
    }
  }

  .iconRow {
    .iconContainer {
      border-bottom: 1px solid #e2001a;
      @include media-breakpoint-down(sm) {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }

    .iconTitle {
      text-transform: uppercase;
      margin-top: 10px;
    }

    .iconText {
      margin-top: 15px;
    }

    @include media-breakpoint-down(sm) {
      .iconText {
        display: none;
      }

      .iconContainer {
        border-bottom: none;
      }

      svg {
        height: 104px;
      }
    }
  }

  .subTitle {
    font-size: 24px;
    font-weight: 600;
  }

  .percentageText {
    font-size: 18px;
    text-transform: uppercase;
    color: #717f8a;
    font-weight: 600;
  }

  .moreBlogs {
    font-size: 24px;
    font-weight: 600;
    text-transform: uppercase;

    .highlightColor {
      color: #e2001a;
    }
  }
}
