#registrationForm .card-body {
  padding: 2rem;
  background-color: #fff;
  // box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  .react-select__placeholder {
    font-size: 14px;
  }
  label.text-muted {
    font-size: 14px;
    margin-bottom: 0;
  }
  legend.text-muted {
    font-size: 14px;
  }

  fieldset.form-group {
    padding-left: 15px;
  }
  *:focus,
  *:active,
  .react-select__control--is-focused {
    outline: none !important;
    box-shadow: none !important;
  }
  input:-webkit-autofill.form-control.is-valid,
  .react-select__value-container--has-value {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") !important;
    border-color: #28a745;
    padding-right: calc(1.5em + 0.75rem);
    background-repeat: no-repeat;
    background-position: center right
      calc(0.375em + 0.1875rem);
    background-size: calc(0.75em + 0.375rem)
      calc(0.75em + 0.375rem);
  }
  input.form-control.form-control,
  .react-select__control {
    margin-bottom: 10px;
    border: none;
    border-bottom: solid 2px $gray200;
    transition: border-color 0.2s ease-in;
    &:focus {
      outline: none;
      box-shadow: none;
    }
    &:focus {
      border-bottom-color: $gray500;
    }
    &.is-valid {
      border-bottom-color: $green400;
    }
    &.is-invalid {
      border-bottom-color: $red400;
    }
  }
  .react-select.valid > .react-select__control {
    border-bottom-color: $green400;
  }
  textarea {
    min-height: 150px;
    margin-top: 0.375rem;
    &::placeholder {
      font-size: 14px;
    }
  }

  // fix for bootstrap validation icon not showing on autofill
  @-webkit-keyframes autofill-valid {
    to {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
      background-color: #fff;
    }
  }
  @-webkit-keyframes autofill-invalid {
    to {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23E9020B' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23d9534f' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
      background-color: #fff;
    }
  }
  input.is-valid:-webkit-autofill {
    -webkit-animation-name: autofill-valid;
    -webkit-animation-fill-mode: both;
  }
  input.is-invalid:-webkit-autofill {
    -webkit-animation-name: autofill-invalid;
    -webkit-animation-fill-mode: both;
  }

  // Styling for react-phone-number-input package components
  .PhoneInputInput {
    border: none;
  }

  .PhoneInput {
    border-radius: 0.25rem;
    padding: 0.34rem 0.75rem;
    margin-bottom: 0;
    border-bottom: solid 2px $gray200;
    transition: border-color 0.2s ease-in;
    &:focus-within {
      outline: none;
      box-shadow: none;
      border-bottom-color: $gray500;
    }
  }

  .is-valid.PhoneInput {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    border-bottom-color: $green400;
    padding-right: calc(1.5em + 0.75rem);
    background-repeat: no-repeat;
    background-position: center right
      calc(0.375em + 0.1875rem);
    background-size: calc(0.75em + 0.375rem)
      calc(0.75em + 0.375rem);
  }

  .is-invalid.PhoneInput {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
    border-bottom-color: $red400;
    padding-right: calc(1.5em + 0.75rem);
    background-repeat: no-repeat;
    background-position: center right
      calc(0.375em + 0.1875rem);
    background-size: calc(0.75em + 0.375rem)
      calc(0.75em + 0.375rem);
  }
}
