#settings {
    .list-group-item a {
        cursor: pointer;
        &.active {
            font-weight: 700;
        }
    }
    .valid-select {
        background-position: 92% 50%;
    }

    .error-message {
        color: #aeb7be;
        font-size: 0.8rem;
      }
}