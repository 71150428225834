.agencyTeaser {
  &.compactView {
    cursor: pointer;
  }

  &.active {
    border: 2px solid $accent;
  }

  .card-body {
    .card-title {
      padding-right: 40px;
      position: relative;
      a,
      h2 {
        color: $gray800;
        font-weight: 700;
        font-size: 20px;
      }
    }

    .withIcon {
      &:after {
        position: absolute;
        right: 0;
        top: 0;
        content: url("/icons/users.svg");
        margin: 0 2px;
        transform: scale(2);
      }
    }

    address {
      color: $gray800;
      margin-bottom: 0rem;
      line-height: 1.5;
    }

    .hours {
      color: $gray-600;
    }

    a {
      color: $gray800;
      text-decoration: underline;
      text-decoration-thickness: 1px;
      text-underline-offset: 2px;
    }
  }
}
.agenciesPage {
  .agencyTeaser {
    height: 100%;
    margin-bottom: 0;
  }
}
