#employerWhy {
  h1 {
    line-height: 1.4em;
  }

  #footer {
    margin-top: 0;
    z-index: 400;
    position: relative;
  }
  p {
    max-width: 700px;
  }
  .bigIcon {
    @include media-breakpoint-down(md) {
      width: 150px;
      height: 150px;
    }
  }

  button.cta.small {
    @include media-breakpoint-up(xl) {
      font-size: 12px;
    }
  }

  ul.bullets {
    list-style: none;
    padding: 0;
    li {
      min-width: 220px;
      padding-left: 1.8em;
      &:before {
        position: relative;
        color: $primary;
        content: "\25A0";
        font-size: 10px;
        font-weight: 800;
        display: inline-block;
        margin-left: -1.8em;
        width: 1.8em;
        top: -0.2em;
      }
      p {
        display: inline;
      }
    }
  }
}
