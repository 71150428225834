#editAgency {
  .map-container {
    min-height: 475px;
  }
  .sticky {
    position: sticky;
    top: 24px;
  }

  .input-group-text {
    background-color: white;
    border: none;
    border-left: none;
    border-right: none;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .autocompleteInput {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-right: none;
    border: none;
  }

  .radiusInput {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  .form-select:focus {
    box-shadow: none;
  }

  .edit-agency-btn {
    position: sticky;
    width: 200px;
    bottom: 16px;
    z-index: 99;
    left: calc(50vw - 100px);


    @include media-breakpoint-up(lg) {
      bottom: 24px;
      left: calc(50vw - 212px);
    }
  }
}