.jobsPage {
  .jobResults {
    h2 span#count {
      font-weight: 700;
      letter-spacing: 1px;
      color: $primary;
    }
  }

  #jobsearchContainer {
    ul {
      list-style: none;
      margin-bottom: 0;
    }

    &.sticky {
      position: sticky;
      top: 20px;
    }
  }

  #facetFilters {
    label {
      padding-top: 3px;
      &:first-letter {
        text-transform: uppercase;
      }
    }
  }

  @include media-breakpoint-down(md) {
    #navSpacer {
      background-color: #fff;
      margin-bottom: 0px;
      height: 20px;
    }
    #jobSearchForm {
      margin-left: -15px;
      margin-right: -15px;
    }
    .jobResults {
      display: flex;
      flex-direction: column;
      > .filterSummary {
        order: 1;
      }
      #resultCount {
        order: 2;
      }
      #noResults {
        order: 4;
      }
      .saved-search-alert {
        order: 3;
      }
      > ul {
        order: 4;
      }
      .pager {
        order: 5;
      }
    }
  }

  .content-section ul {
    list-style-type: none;
    padding-left: 1.8em;
    li {
      &:before {
        color: $primary;
        content: "\25A0";
        font-size: 10px;
        font-weight: 800;
        display: inline-block;
        margin-left: -1.8em;
        width: 1.8em;
        top: -0.3em;
      }
    }
    p {
      display: inline;
    }
  }

  .jobs-button {
    height: 38px;
    position: relative;
    width: auto;
    max-width: 220px;

    @include media-breakpoint-down(lg) {
      max-width: 100%;
    }

    @include media-breakpoint-up(xxl) {
      width: 220px;
    }

    &.aside {
      width: 100%;
      max-width: 100%;
      border-color: $gray300;
    }
  }

  button.jobs-button {
    color: $gray700;
    border-color: $gray400;

    &:hover,
    &:hover > *,
    &:active,
    &:active > *,
    &:focus,
    &:focus > * {
      border-color: black;
      color: black;
      background-color: white;
      box-shadow: none;

      .badge {
        color: white;
      }
    }
  }
}

#filterSelect.form-select {
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.0002 5.83L15.1702 9L16.5802 7.59L12.0002 3L7.41016 7.59L8.83016 9L12.0002 5.83ZM12.0002 18.17L8.83016 15L7.42016 16.41L12.0002 21L16.5902 16.41L15.1702 15L12.0002 18.17Z' fill='black'/%3E%3C/svg%3E%0A");
  background-size: 20px auto, 100%;
}

.badge {
  padding: 7px 9px 7px 9px;
}
