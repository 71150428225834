.articleContainer {
    .intro {
        font-weight: 700;
    }
    @media (max-width: 576px) {
        h1 {
            font-size: 1.5rem;
        }
    }
    max-width: 860px;
    margin: 0 auto;
    p {
        line-height: 1.5;
    }
}