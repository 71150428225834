#passwordReset {
  @include media-breakpoint-up(lg) {
    .row.divider {
      margin-top: 10vh;
      margin-bottom: 10vh;
      overflow: hidden;
      position: relative;
      &:after {
        content: "";
        height: 100%;
        background-color: $primary;
        width: 2px;
        position: absolute;
        right: 1px;
        left: 50%;
      }
    }
  }
  @include media-breakpoint-down(lg) {
    .col-lg-4:nth-of-type(1) {
      border-bottom: solid 1px #e9e9e9;
    }
  }
}
