#settings, #user-registration-form {
  .PhoneInputCountry {
    padding: 0 0.75rem 0 0.5rem;
  }

  .PhoneInput {
    border-radius: 0.25rem;
    padding: 0.375rem 0.75rem;
    border: solid 1px $gray400;
    transition: border-color 0.2s ease-in;
    &:focus {
      outline: none;
    }
    &.submitting .PhoneInputCountrySelect {
      display: none;
    }
  }

  .PhoneInputInput {
    border: none;
    outline: none;
  }

  .is-valid.PhoneInput {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    border-color: #28a745;
    padding-right: calc(1.5em + 0.75rem);
    background-repeat: no-repeat;
    background-position: center right
      calc(0.375em + 0.1875rem);
    background-size: calc(0.75em + 0.375rem)
      calc(0.75em + 0.375rem);
    &:focus-within {
      box-shadow: 0 0 0 0.25rem
        rgb(25 135 84 / 25%);
    }
  }

  .is-invalid.PhoneInput {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
    border-color: #dc3545;
    padding-right: calc(1.5em + 0.75rem);
    background-repeat: no-repeat;
    background-position: center right
      calc(0.375em + 0.1875rem);
    background-size: calc(0.75em + 0.375rem)
      calc(0.75em + 0.375rem);
    &:focus-within {
      box-shadow: 0 0 0 0.25rem
        rgb(220 53 69 / 25%);
    }
  }
}
