button.cta {
  background-color: transparent;
  color: $primary;
  padding: 8px 35px 8px 15px;
  font-size: 14px;
  border: solid 1px $primary;
  position: relative;
  border: solid 1px $primary;
  font-weight: 700;
  &:not(.btn-outline-primary) {
    border: none;
  }

  .btn-outline-white {
    color: #fff;
    border: solid 1px #fff;
  }


  svg {
    position: absolute;
    bottom: 12px;
    display: inline-block;
    right: 12px;
    transition: all 0.2s ease-in-out;
    .fillColor {
      fill: $primary;
    }
  }
  &:hover {
    svg {
      right: 8px;
    }
  }
  &:focus {
    outline: 1px solid $primary;
  }
}

.btn-outline-white {
  background-color: transparent;
  color: #fff;
  padding: 8px 35px 8px 15px;
  font-size: 14px;
  border: none;
  position: relative;
  border: solid 1px #fff;
  font-weight: 700;

  svg {
    position: absolute;
    bottom: 12px;
    display: inline-block;
    right: 12px;
    transition: all 0.2s ease-in-out;
    .fillColor {
      fill: #fff;
    }
  }
  &:hover {
    svg {
      right: 8px;
    }
  }
  &:focus {
    outline: 1px solid #fff;
  }
}
.btn-outline-primary:hover {
  background-color: $primary;
  color: white;
}

button.cta.red {
  background-color: $primary;
  color: #fff;
  svg .fillColor {
    fill: #fff;
  }
  &:hover {
    background-color: $Syn-darkRed;
    color: #fff;
  }
}

.btn-outline-primary:hover svg .fillColor {
  fill: #fff;
}

button.cta.big {
  width: 100%;
  padding: 20px 50px;
  font-size: 1em;
  margin-bottom: 20px;
  svg {
    left: 25px;
    bottom: 25px;
    transform: rotate(90deg) scale(2);
  }
  &:hover {
    svg {
      bottom: 20px;
    }
  }
}


button.cta.noArrow,
button.cta.big.noArrow {
  padding: 10px 40px 10px 40px;
  svg {
    display: none;
  }
  &:hover {
    right: 0px;
  }
}

button.cta:disabled {
  background-color: $gray300 !important;
}
