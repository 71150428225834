#fullWidthImgHeader {
  .pageHeaderContainer {
    position: relative;
    margin: 0 auto;
    min-height: 300px;
    background-color: $light;
    background-repeat: no-repeat;
    background-size: cover;
    color: #000;

    &.fullWidthImg {
      min-height: 0px;
      height: 350px;
      &.bigHeader  {
        height: 450px;
      }

      img {
        object-fit: cover;
        width: 100%;
      }
      @include media-breakpoint-down(lg) {
        height: 170px;
      }
    }
  }
}