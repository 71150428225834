.navbar {
  background-color: #fff;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 1.25px;
  line-height: normal;
  position: relative;
  z-index: 100;

  a {
    text-decoration: none;
  }

  hr {
    border: 1px solid #e8e9f3;
    opacity: 0.5;
  }

  svg {
    color: #2f3336;
  }

  .navbar-logo {
    height: 55px;
    width: 68px;
    z-index: 200;

    .navLogo {
      position: absolute;
      top: 0;
      height: 85px;
      width: 68px;
    }

    @include media-breakpoint-down(lg) {
      height: 55px;
      width: 140px;
      svg.navLogo {
        height: 68px;
        width: 148px;
      }
    }
  }

  .navbar-navigation {
    background-color: #fff;
    order: 1;

    @include media-breakpoint-down(lg) {
      padding-top: 80px;

      #mainNav {
        padding-bottom: 120px;
        min-height: 100vh;
      }
    }

    @include media-breakpoint-down(lg) {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      order: 3;

      & ul {
        max-height: 100vh;
        background-color: #fff;
        overflow-y: scroll;
      }
    }

    transition: visibility 0.3s, opacity 0.3s,
      background-color 0.3s ease-in;

    @include media-breakpoint-down(lg) {
      visibility: hidden;
      opacity: 0;

      &.show {
        opacity: 1;
        visibility: visible;
        transition: visibility 0.3s, opacity 0.3s,
          background-color 0.05s ease-in;
      }
    }

    .nav-link {
      padding: 14px 0;
      position: relative;

      @include media-breakpoint-up(lg) {
        padding-left: 8px;
        padding-right: 8px;
      }

      > li.active:before,
      &.a.active:before,
      &.active:before {
        content: "";
        height: 4px;
        background-color: $primary;
        display: block;
        position: absolute;
        width: 83%;
        bottom: 0;

        @include media-breakpoint-down(lg) {
          display: none;
        }
      }
    }
  }

  .nav-link {
    height: 55px;
  }

  .languageDropdown:hover .languageMenu {
    display: block;
  }

  .notifications-badge {
    height: 18px;
    width: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 0.25px;
  }

  .navbar-menubutton {
    z-index: 200;
    @include media-breakpoint-down(lg) {
      order: 2;
    }
  }

  .dropdown-menu {
    border-width: 0;
    font-weight: 400;
    letter-spacing: 0.25px;
    margin-top: 0;
    min-width: 240px;
    a {
      color: #000;
    }
    @include media-breakpoint-down(lg) {
      &.alwaysOpenMobile {
        display: block;
      }
    }
  }

  .dropdown-menu-end {
    right: 0;
    left: auto;
  }

  .dropdown-item {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.25px;

    @include media-breakpoint-up(lg) {
      letter-spacing: 1.25px;
    }
  }

  .dropdown-item:hover,
  .dropdown-item:focus,
  .dropdown-item:active {
    background-color: $gray100;
  }

  .mainDropdown:hover .mainMenu {
    display: block;
  }

  .mainMenu,
  .languageMenu {
    .dropdown-item {
      margin-bottom: 8px;
    }

    @include media-breakpoint-up(lg) {
      box-shadow: 0px 0px 5px 0px
        rgba(0, 0, 0, 0.1);
      border-radius: 10px;

      .dropdown-item:last-child {
        margin-bottom: 0px;
      }
    }
  }

  .languageDropdown-title {
    padding-top: 7px;
    padding-bottom: 7px;
    &:hover .languageDropdown-globe {
      background-color: $gray200;
    }
  }

  .languageDropdown-globe {
    border-radius: 50%;
    padding: 0.3rem;
  }

  .text-secondary {
    color: #2f3336 !important; // bootstrap override
  }

  .navbar-avatar.borderless {
    border-color: #fff;
  }
}

#navSpacer {
  height: 40px;
  background-color: #2f3336;
  position: relative;
  margin-bottom: 20px;
}
