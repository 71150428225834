.mySynergie-profile {
  .mysynergie-profile-section {
    @include media-breakpoint-up(md) {
      min-height: 600px;
    }

    // Make 'regular' and phone inputs the same height as react-select inputs
    input.form-control,
    .PhoneInput {
      height: 38px;
    }

    // Exception for file upload buttons
    #fileUploadButton {
      input {
        height: 34px;
      }
    }

    .settings-card {
      position: relative;
      height: 100%;

      .settings-card-foreground {
        background-color: white;
        font-size: 16px;

        @include media-breakpoint-up(md) {
          height: 100%;
          border: 1px solid $gray300;
          border-radius: 10px;
          font-size: 14px;
          transition: all 0.3s;

          &:hover,
          &:focus {
            translate: 3px -3px;
          }

          &:active {
            translate: 1px -1px;
          }
        }

        .section-icon {
          height: 20px;
          width: 20px;

          @include media-breakpoint-up(md) {
            height: 24px;
            width: 24px;
          }
        }

        .section-title {
          font-weight: 400;
          @include media-breakpoint-up(md) {
            font-weight: 500;
          }
        }

        .section-description {
          color: $gray600;
        }
      }

      .settings-card-background {
        background-color: $gray300;
        border-radius: 10px;
        height: 100%;
        width: 100%;
        z-index: -1;

        position: absolute;
        top: 0;
        left: 0;
      }
    }

    // border coloring and checkbox icon for valid react-select inputs
    .valid {
      .react-select__control {
        border-color: #198754;

        &:focus-within {
          box-shadow: 0 0 0 0.25rem
            rgba(25, 135, 84, 0.25);
        }
      }

      input:-webkit-autofill.form-control.is-valid,
      .react-select__value-container--has-value {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") !important;
        padding-right: calc(1.5em + 0.75rem);
        background-repeat: no-repeat;
        background-position: center right
          calc(0.375em + 0.1875rem);
        background-size: calc(0.75em + 0.375rem)
          calc(0.75em + 0.375rem);
      }
    }

    // avoid checkbox labels from being green when 'valid'
    .form-check-label {
      color: inherit;
    }

    label {
      font-size: 16px;
    }

    .profileCard {
      background-color: $gray100;
      border-radius: 10px;

      .accent {
        color: $accent;
      }

      .label {
        font-size: 12px;
        letter-spacing: 1.5px;
      }

      .button-icon {
        background-color: inherit;
        color: inherit;
        border: none;

        &:hover,
        &:focus,
        &:active {
          box-shadow: none;
        }
      }
    }
  }

  .profile-header-icon-container {
    background-color: $gray100;
    border: 2px solid $gray800;
    border-radius: 50%;
    color: $gray800;
    height: 40px;
    width: 40px;
  }

  .profile-header-icon {
    display: table-cell;
    // height: 27px;
    // min-width: 27px;
    text-align: center;
    vertical-align: middle;

    svg {
      width: 24px;
      height: 24px;
    }
  }

  .combined-select .flex-grow-1 {
    max-width: calc(100% - 36px - 132px); // 100% - currency width - salary-period width
  }
}
