#companyPage {
  h2 {
    margin-top: 32px !important;
  }

  .row {
    position: relative;
  }

  .companyLogoContainer {
    position: absolute;
    top: -180px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .companyLogo {
      border: solid 1px $gray-200;
      max-height: 100px;
      padding: 5px;
    }
    .youtubeContainer {
      max-width: 450px;
      display: none;
      @include media-breakpoint-up(lg) {
        display: block;
      }
    }

    @include media-breakpoint-up(lg) {
      position: relative;
      top: -150px;
      height: 100%;

      .companyLogo {
        max-height: 200px;
        padding: 15px;
      }

      .youtubeContainer {
        max-width: 100%;
      }
    }
    &.noheader {
      position: relative;
      top: 0;
      margin-bottom: 40px;
      .youtubeContainer {
        height: 200px;
        .iframeContainer {
          max-width: 700px;
          margin: 0 auto;
          display: block;
          @include media-breakpoint-down(lg) {
            display: none;
          }
        }
      }

      @include media-breakpoint-up(lg) {
        justify-content: start;
      }
      @include media-breakpoint-up(xs) {
      }
    }
  }

  .youtubeContainerMobile {
    @include media-breakpoint-up(lg) {
      display: none;
    }
    .iframeContainer {
      max-width: 700px;
      margin: 0 auto;
      display: block;
    }
  }

  #companyInfo {
    h1 {
      padding-right: 40px;
    }
    position: relative;
    .companyDescription {
      color: $gray-600;
    }
    &::after {
      content: url("/icons/office-building.svg");
      display: block;
      position: absolute;
      right: 25px;
      top: 0;
      width: 27px;
      height: 28px;
      transform: scale(1.4);
    }
    @include media-breakpoint-down(lg) {
      margin-bottom: 40px;
    }
  }
}
