// https://stackoverflow.com/questions/13183421/how-to-change-placeholder-color-on-focus

.thankyouInput::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0);
}
.thankyouInput:focus::-webkit-input-placeholder {
  color: #999;
}

.thankyouDateInput::-webkit-datetime-edit {
  color: rgba(0, 0, 0, 0);
}

.thankyouDateInput:focus::-webkit-datetime-edit {
  color: #999;
}

.is-valid.thankyouDateInput::-webkit-datetime-edit {
  color: var(--bs-body-color);
}
