.notification-item {
  border-width: 1px;
  border-style: solid;
  border-color: $gray300;
  border-radius: 10px;
  font-size: 12px;
  letter-spacing: 0.4px;
  line-height: normal;
  position: relative;
  transition: background-color 0.5s ease;

  &:hover {
    border-color: $gray500;
  }

  &.unread {
    background-color: $mutedaccent;
    border-color: $accent;

    &:hover {
      background-color: $hoveraccent;
    }
  }

  .unread-dot {
    background-color: $accent;
    border-radius: 50%;
    height: 8px;
    width: 8px;
    position: absolute;
    top: 24px;
  }

  .timestamp {
    color: $gray700;
    font-size: 10px;
    font-weight: 400;
    letter-spacing: 1.5px;
  }
}
