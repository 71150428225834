#fullWidthHeader {
  .pageHeaderContainer {
    position: relative;
    margin: 0 auto;
    min-height: 300px;
    background-color: $light;
    background-repeat: no-repeat;
    background-size: cover;
    color: #000;

    &.fullWidth {
      max-width: 1920px;
      @include media-breakpoint-down(md) {
        min-height: 160px;
      }
      @include media-breakpoint-up(md) {
        min-height: 350px;
      }
      h1 {
        text-transform: uppercase;
        strong {
          color: white;
        }
      }
    }

    &.darkened {
      background-color: rgb(113, 127, 138);
      background-blend-mode: multiply;
      color: white;
      a {
        color: white;
      }
    }

    .textWrapper {
      width: 100%;
      max-width: 1320px;
      @include media-breakpoint-down(xxl) {
        max-width: 1140px;
      }
      @include media-breakpoint-down(xl) {
        max-width: 960px;
      }
    }

    .textContainer {
      max-width: 400px;
      hr {
        opacity: 1;
        color: white
      }
      &.withoutBg {
        hr {
          color: $primary;
        }
      }
    }
  }
}