.jobMatchTeaser {
    text-decoration: none;
    position: relative;
    //display: flex;
    justify-content: space-between;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0px;
    margin-bottom: 32px;
    padding: 20px 0 20px 20px;
    min-height: 140px;

    .btn:focus {
      box-shadow: none;
    }

    @include media-breakpoint-down(md) {
      flex-direction: column;
    }

    a {
      text-decoration: none;
      h2 {
        margin-top: 0 !important;
      }
    }

    a:hover {
      color: $black;
    }

    h2 {
      color: $black;
      text-decoration: none;
    }

    .card-subtitle {
      display: inline-block;
      margin-right: 10px;
      color: $gray-600;
      text-transform: uppercase;
      letter-spacing: 0.05em;

      padding-left: 10px;
      border-left: solid 1px $gray-600;

      &:first-of-type {
        border: none;
        padding-left: 0;
      }
    }

    .teaserLeft {
      //max-width: 100%;
      padding-right: 10px;
      .updated {
        color: #495057;
        svg {
          margin-right: 8px;
          position: relative;
          bottom: 2px;
          width: 18px;
          height: 18px;
        }
      }
      .location {
        color: $gray-600;
        text-transform: uppercase;
        letter-spacing: 0.05em;
        margin-right: 10px;
        text-transform: uppercase;
        @include media-breakpoint-up(sm) {
        }
        @include media-breakpoint-up(md) {
          position: absolute;
          top: 20px;
          right: 15px;
        }
      }
      @include media-breakpoint-up(md) {
        max-width: 70%;
      }
      @include media-breakpoint-down(md) {
        margin-bottom: 30px;
        h3 {
          margin-bottom: 15px;
        }
      }
    }
    .feedBackButtons {
      float: left;
      @include media-breakpoint-down(sm) {
        button {
          padding: 5px 5px;
          margin-right: 15px;
        }
      }
    }
    .teaserRight {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      align-items: flex-end;

      @include media-breakpoint-down(md) {
        align-items: flex-start;
      }
    }
  }