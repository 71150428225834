.mysynergie-applications {
  .application-teaser {
    background-color: transparent;
    border-color: $gray-300;
    border-style: solid;
    border-width: 1px;
    border-radius: 10px;
    box-shadow: none;
    color: #2f3336;
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    letter-spacing: 0.1px;
    text-align: start;
    width: 100%;

    hr {
      width: 100%;
    }

    &:focus,
    &:active,
    &:hover {
      box-shadow: none;
    }
  }
}
