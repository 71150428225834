.flex-container{
  display: flex;
  justify-content: center;
  align-items:center;
  min-height: 87vh;
}

.error-page-title {
  font-weight: bold;
  letter-spacing: 0.21px;
  color: #2F3336;
  text-align: center;
  margin-bottom: 15px;
  margin-top: 20%;
}
.error-page-text{
  font-weight:200;
  font-size: 14px;
  letter-spacing: 0.1px;
  color: #717F8A;
  text-align: center;
  margin-bottom: 96px;
}

.error-page-404{
  display: flex;
  justify-content: center;
  align-items:center;
}

.error-text-404{
  font-size: 48px;
  font-weight: 200;
}

.error-page-links{
  display: flex;
  justify-content: center;
  margin-top: 90px;
  margin-bottom: 30%;
  a{
    margin-bottom: 15px;
    .error-cta{
      min-width: 220px;
    }

  }
  a:first-child{
    margin-right: 10px;
  }
  a:nth-child(2){
    margin-left: 10px;
  }
  @include media-breakpoint-down(sm) {
    flex-direction: column;
    align-items:center;
    a:first-child{
      margin-right: 0px;
    }
    a:nth-child(2){
      margin-left: 0px;
    }
  }
}
