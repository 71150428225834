#jobSearchForm {
  h1,
  h3 {
    color: #ffffff;
  }
  h1 {
    font-size: calc(1.265rem + 0.18vw);
    strong {
      font-weight: bold;
      color: #ffffff;
    }
  }
  .input-group  {
    border: #fff;
    border-radius: 0.25rem;
    #what {
      border: #fff;
      border-radius: 0.25rem;
      padding-left: 40px;
    }
    button.btn-clear {
      background-color: #fff;
      border: none;
      border-radius: 0.25rem;
      position: absolute;
      right: 0;
      z-index: 4;
      &:hover{
        color: $gray900;
      }
    }
    .search-field-icon {
      color: #212529;
      position: absolute;
      left: 10px;
      top: 7px;
      z-index: 4;
    }
    .col-8 {
      position: relative;
      button.btn-clear {
        border-radius: 0;
        margin-right: 1px;
        top: 0;
        right: 0;
      }
    }
  }
  @include media-breakpoint-up(lg) {
    ::-webkit-input-placeholder {
      /* WebKit browsers */
       color: transparent;
  }
   :-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
       color: transparent;
  }
   ::-moz-placeholder {
      /* Mozilla Firefox 19+ */
       color: transparent;
  }
   :-ms-input-placeholder {
      /* Internet Explorer 10+ */
       color: transparent;
  }
   input::placeholder {
       color: transparent;
  }
   textarea::-webkit-input-placeholder {
      /* WebKit browsers */
       color: transparent;
  }
   textarea:-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
       color: transparent;
  }
   textarea::-moz-placeholder {
      /* Mozilla Firefox 19+ */
       color: transparent;
  }
   textarea:-ms-input-placeholder {
      /* Internet Explorer 10+ */
       color: transparent;
  }
   textarea::placeholder {
       color: transparent;
  }
  }
  @include media-breakpoint-down(md) {
    h1 span {
      display: none;
    }
  }
}
