@keyframes passwordLoading {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: 100% 100%;
  }
}

#passwordResetRequest {
  form {
    .inputWithIconContainer {
      position: relative;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;


      img,
      svg {
        margin: 0 10px;
        position: absolute;
        width: 20px;
        height: auto;
        top: 10px;
      }

      input {
        padding-left: 40px;
        @include media-breakpoint-up(md) {
          min-width: 350px;
        }
      }
    }
  }
}