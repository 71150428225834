.mysynergie-dashboard {
  h1 {
    font-size: 34px;
  }

  h2 {
    font-weight: 600;
  }

  // SECTIONS

  .mysynergie-dashboard-header {
    text-align: center;

    a {
      font-weight: 600;
      letter-spacing: 1.25px;
    }

    .welcome {
      font-weight: 500;
    }

    .dashboard-link-icon {
      background-color: $yellow;
      border-radius: 50%;
      height: 32px;
      width: 32px;

      transition: all 0.2s ease-in-out;
    }

    .profile-link:hover {
      .dashboard-link-icon {
        transform: translate(4px);
      }
    }
  }

  .mysynergie-dashboard-notifications {
    a {
      font-weight: 600;
      letter-spacing: 1.25px;
    }
  }

  .mysynergie-dashboard-overview {
    .overview-tile {
      background-color: $gray100;
      border-radius: 10px;
      min-height: 143px;
      .overview-tile-title {
        font-size: 10px;
        letter-spacing: 1.2px;
      }

      .overview-tile-amount {
        font-family: "Montserrat", sans-serif;
        font-size: 34px;
        font-weight: 500;
        letter-spacing: 0.25px;
        line-height: 1;
      }

      .overview-tile-icon {
        background-color: $gray800;
        border-radius: 50%;
        color: $gray100;
        height: 24px;
        width: 24px;
      }


      .textContainer {
        @include media-breakpoint-down(sm) {
          min-height: 24px;
        }
      }

      svg.chevron-right {
        transition: all 0.2s ease-in-out;
      }

      &:hover {
        svg.chevron-right {
          transform: translate(4px);
        }
      }
    }
  }

  .mysynergie-dashboard-agency {
    .dashboard-agency {
      border-radius: 10px;

      h3 {
        font-size: 20px;
        font-weight: 600;
        text-transform: uppercase;
      }

      a {
        text-decoration: none;
      }
    }
  }

  .mysynergie-dashboard-jobsuggestions {
    letter-spacing: 0.25px;

    @include media-breakpoint-down(md) {
      button {
        border-radius: 100%;
      }

      a {
        color: black;
        button {
          border-radius: 5px;
        }
      }
    }

    hr {
      color: $gray300;
      opacity: 1;
    }
  }
}
