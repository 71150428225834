#dimona {
  p {
    max-width: 55em;
  }
  a.tel {
    color: $gray500;
    text-decoration: none;
    display: block;
    > * {
      display: inline-block;
      vertical-align: top;
    }
    svg {
      margin-right: 12px;
    }
    span {
      display: block;
      font-weight: 600;
    }
    .number {
      line-height: 1;
      font-size: 25px;
      font-weight: 400;
      color: $black;
    }
    @include media-breakpoint-down(md) {
        position: relative;
        right: 15px;
    }
  }
  ul.topList {
    padding: 0;
    list-style-type: none;
    svg {
      margin-right: 20px;
    }
    span {
      font-weight: 600;
      display: block;
      margin-top: 10px;
      @include media-breakpoint-up(md) {
        display: inline-block;
        width: calc(100% - 50px);
      }
      padding-bottom: 8px;
      border-bottom: solid 1px $gray300;
    }
  }

  ul.bullets {
    list-style: none;
    padding: 0;
    @include media-breakpoint-up(md) {
        margin: 10px 0 20px 48px;
    }
    li {
      margin: 15px 0 15px 0;
      padding-left: 1.8em;
      &:before {
        color: $primary;
        content: "\25A0";
        font-size: 10px;
        font-weight: 800;
        display: inline-block;
        margin-left: -1.8em;
        width: 1.8em;
        top: -0.3em;
      }
      p {
        display: inline;
      }
    }
  }
}
