#loginContainer {
  #providers button {
    span {
      min-width: 180px;
      // text-align: left;
    }

    &:hover {
      background-color: #f0f0f0;
    }

    &:focus {
      box-shadow: none;
    }
  }

  >.col-lg-6:first-of-type {
    @include media-breakpoint-up(sm) {
      border-right: 1px solid $gray300;
    }
  }

  #or {
    text-align: center;
    background-color: #fff;
    padding: 20px;
    width: 80px;
    position: relative;

    &::before {
      content: "";
      display: block;
      position: absolute;
      height: 1000px;
      width: 2px;
      z-index: -1;
      top: calc(100% - 500px);
      left: 50%;
      background: $gray400;

      @media (max-width: 768px) {
        width: 800px;
        left: -400px;
        height: 2px;
        top: 50%;
      }
    }
  }
}

form#login-with-email {
  input[name='password'] {
    border-right: 0;
  }

  .input-group-text {
    background-color: $white;
  }


  input.form-control.form-control {

    &:focus {
      border-color: $gray500;
      + span.input-group-text {
        border-color: $gray500;
      }
    }
    &.is-valid {
      border-color: $success;
      ~span.input-group-text {
        border-bottom-color: $success;
      }
    }

    &.is-invalid {
      border-color: $red400;
      + span.input-group-text {
        border-color: $red400;
      }
    }
  }







  button.cta {
    &[disabled] {
      color: $gray600;
    }
  }

  button {
    font-size: 14px;
  }
}