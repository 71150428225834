@include media-breakpoint-up(lg) {
  #facetSideNav-modal, #facetSideNav {
    display: none;
  }
}


#facetSideNav {
  background-color: $white;
  width: 400px;
  right: -400px;
  max-width: 75vw;
  position: fixed;
  transition: right 0.2s ease-in;
  top: 0;
  bottom: 0;
  z-index: 1050;
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  &::-webkit-scrollbar {
    display: none;
  }
  h2 {
    font-size: 1.2rem;
  }
  h2, .collapse-toggle {
    font-weight: $font-weight-bolder;
  }
  .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48cGF0aCBkPSJNNDA1IDEzNi43OThMMzc1LjIwMiAxMDcgMjU2IDIyNi4yMDIgMTM2Ljc5OCAxMDcgMTA3IDEzNi43OTggMjI2LjIwMiAyNTYgMTA3IDM3NS4yMDIgMTM2Ljc5OCA0MDUgMjU2IDI4NS43OTggMzc1LjIwMiA0MDUgNDA1IDM3NS4yMDIgMjg1Ljc5OCAyNTZ6Ii8+PC9zdmc+");
  }
  li {
    &::marker {
      position: absolute;
      color: #fff;
      content: '';
    }

  }

  &.open {
    right: 0;
  }
  .filterSummary {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    flex-direction: column;
    align-items: stretch;
    border-bottom: none;
    #activeFilters {
      flex-direction: column;
      width: 100%;
    }
  }
  .collapse-toggle {
    font-size: 1rem;
    svg {
      transition: all 0.2s ease-in-out;
      margin-right: 0.75rem;
    }
    &.open svg {
      transform: rotate(180deg);
    }
  }
  .activeFacet span, button#filter-reset {
    display: block;
    margin-right: 0.75rem;
  }

  li.activeFacet button {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $white;
    border-bottom: solid 1px $border-color;
    padding: 12px;
    margin-bottom: 6px;
    // padding-bottom: 6px;
  }

  #sidenav-submit {
    padding: 10px;
    background-color: #fff;
  }
}
