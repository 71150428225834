#squareHeader {
  #headerContainer {
    h1 {
      strong {
        color: $primary;
      }
    }
    @include media-breakpoint-only(md) {
      h1 {
        font-size: 24px;
      }
      p {
        font-size: 16px;
      }
    }

    #headerImgContainer {
      @include media-breakpoint-down(sm) {
        padding-right: 0;
        padding-left: 0;
      }

      #header-img {
        width: 100%;
        object-fit: cover;
        max-height: 658px;
        @include media-breakpoint-down(sm) {
          max-height: 300px;
        }
      }
    }
    #header-text {
      @include media-breakpoint-up(lg) {
        margin-top: 10%;
        font-size: 20px;
        position: absolute;
        z-index: 1;
      }
    }
  }
}
