.facetFilter {
  h3 {
    border-style: none;
    border-radius: 0px;
    &:active {
      border: none
    }
  }
  label {
    padding-top: 2px;
  }
  input:hover,
  label:hover {
    cursor: pointer;
    color: $primary;
  }

  @include media-breakpoint-up(lg) {
    h3.no-border-bottom-lg {
      border-bottom: none !important;
    }
  }
  @include media-breakpoint-down(lg) {
    background-color: inherit;
    border: none !important;
    margin-bottom: 0;
    button {
      font-size: 18px;
      color: $primary;
      border-bottom: $border-color;
      position: relative;
      svg {
        transition: all 0.2s;
      }
    }
  }
  svg {
    transition: all 0.2s ease-in-out;
  }
  &.open svg {
    transform: rotate(180deg);
  }
}
