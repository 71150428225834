#post {
  #footer {
    margin-top: 0px;
  }
  article {
    max-width: 1920px;
    margin: 0 auto;
    span.category {
      font-weight: 600;
      font-size: 14px;
      text-transform: uppercase;
      color: $gray800;
      &:before {
        content: "";
        display: inline-block;
        position: relative;
        height: 5px;
        width: 20px;
        margin-right: 6px;
      }
      &.event,
      &.story {
        color: $red;
        &:before {
          background-color: $red;
        }
        .card-text {
          border-bottom: solid 1px $red;
        }
      }
      &.news {
        color: $blue;
        &:before {
          background-color: $blue;
        }
        .card-text {
          border-bottom: solid 1px $blue;
        }
      }
      &.tips {
        color: $yellow;
        &:before {
          background-color: $yellow;
        }
        .card-text {
          border-bottom: solid 1px $yellow;
        }
      }
      &.construct {
        color: $yellow;
        &:before {
          background-color: $yellow;
        }
        .card-text {
          border-bottom: solid 1px $yellow;
        }
      }
      &.fact {
        color: $accent;
        &:before {
          background-color: $accent;
        }
        .card-text {
          border-bottom: solid 1px $accent;
        }
      }
    }

    .author {
      display: flex;
      align-items: center;
      img {
        width: 60px;
        height: auto;
      }
      > div,
      > img {
        display: inline-block;
      }
      > div {
        margin-left: 10px;
        span {
          &.name {
            font-size: 15px;
            font-weight: 400;
          }
          &.published {
            font-size: 13px;
          }
          display: block;
        }
      }
    }
    #blogHeaderImg {
      max-height: 348px;
      width: 100%;
      max-width: 100%;
      object-fit: cover;
    }
  }
  h2 {
    font-size: 1.4em;
    text-transform: uppercase;
    strong {
      color: $primary;
    }
  }

  .ctaCards {
    border-top: solid 1px $primary;
  }

  article ul {
    list-style-type: none;
    padding-left: 1.8em;
    li {
      &:before {
        color: $primary;
        content: "\25A0";
        font-size: 10px;
        font-weight: 800;
        display: inline-block;
        margin-left: -1.8em;
        width: 1.8em;
        top: -0.3em;
      }
    }
    p {
      display: inline;
    }
  }
}
