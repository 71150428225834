.squardImageContainer {
    position: relative;
    height: 435px;
    width: 326px;
    margin: 0 auto 15px auto;
    @include media-breakpoint-down(sm) {
      height: 270px;
      width: 200px;
    }


  .imgFill {
    position: relative;
    height: 435px;
    width: 326px;
    z-index: 1;
    background-position: center;
    background-size: cover;
    @include media-breakpoint-down(sm) {
      height: 270px;
      width: 200px;
    }
  }

  .redSquare {
      width: 150px;
      height: 110px;
      background-color: #e2001e;
      position: absolute;
      bottom: -15px;
      right: -15px;
      @include media-breakpoint-down(sm) {
        width: 92px;
        height: 65px;
        bottom: -10px;
        right: -10px;
      }
    }
}