#profile-modal {

    h1,
    h2,
    h3 {
        text-transform: initial;
    }



    input[type="radio"],
    input[type="checkbox"] {
        &:checked {
            background-color: $accent;
            border-color: $accent;
            border-color: $accent;
        }

        &:focus {
            border-color: initial;
            box-shadow: none;
            border-color: rgba(0, 0, 0, 0.25);
        }
    }

    .workpreference-option {
        transition: border-color 0.2s ease-in-out;

    }

    input[name='cv-name'] {
        border-right: 0;
        border-left: 0;
    }

    .modal-footer {
        border-top-color: #fff;
    }

    @include media-breakpoint-down(md) {
        .modal-content {
            min-height: 95vh;
        }
    }
}