#heroImageHeader {
  .heroContainer {
    height: 100vh;

    #heroImgContainer {
      z-index: -1;
      height: 100vh;
      width: 100vw;
      background-repeat: no-repeat;
      background-size: cover;
      top: 0px;
      position: fixed;
      background-color: rgb(113, 127, 138);
      background-blend-mode: multiply;
    }

    .heroTextContainer {
      position: relative;
      color: white;
      width: 100vw;
      height: 90vh;

      h1 {
        font-size: 32px;
      }
    }

    .readMoreContainer {
      position: absolute;
      bottom: 0px;
      width: 100%;

      .readMoreText {
        font-size: 12px;
        margin-bottom: 3px;
      }
    }
  }
}