.card.eventTeaser {
    text-decoration: none;
    margin-bottom: 0;
    height: 100%;
    p {
      flex-basis: 50px;
    }
    .iconContainer {
      width: 25px;
      vertical-align: top;
      display: inline-block;
    }

    address {
      span,
      .iconContainer {
        display: inline-block;
      }
      span {
        width: calc(100% - 25px);
      }
    }

    time {
      > div {
        display: inline-block;
        max-width: 50%;
      }
    }
    .ctaContainer {
      border-top: solid 1px $gray700;
      button.cta {
        color: $gray700;
        font-weight: 400;
        path.fillColor {
          fill: $gray700;
        }
        &:focus {
          outline: none;
        }
      }
    }
  }
