#horizontalSplitHeader {
  .pageHeaderContainer {
    position: relative;
    margin: 0 auto;
    min-height: 300px;
    background-color: $light;
    background-repeat: no-repeat;
    background-size: cover;
    color: #000;

    &.horizontalSplit {
      height: 450px;
      @include media-breakpoint-down(md) {
        min-height: 240px;
        height: 240px;
      }
    }
  }
}