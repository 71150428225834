#eventBanner {
  .eventBannerContainer {
    position: relative;
    margin: 0 auto;
    min-height: 300px;
    height: 450px;
    background-color: $light;
    background-repeat: no-repeat;
    background-size: cover;
    color: #000;

    @include media-breakpoint-down(sm) {
      height: 400px;
    }

    &.darkened {
      @include media-breakpoint-down(sm) {
        background-color: rgb(181, 194, 202);
      }
      background-color: rgb(213, 227, 236);
      background-blend-mode: multiply;
      color: white;
      a {
        color: white;
      }
    }

    .textWrapper {
      max-width: 1320px;
    }

    .textContainer {
      h2 {
        font-size: 1.7rem;
        text-transform: none;
    }
    }
  }

}