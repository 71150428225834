.mysynergie-searches {
  hr {
    color: $gray300;
    opacity: 1;
  }

  .header-icon-container {
    background-color: $gray800;
    border-radius: 50%;
    color: $gray100;
    height: 40px;
    width: 40px;
  }

  .header-icon {
    display: table-cell;
    height: 27px;
    min-width: 27px;
    text-align: center;
    vertical-align: middle;
  }

  .breadcrumb .breadcrumb-item:last-child {
    color: $primary;
  }

  .saved-search-teaser {
    border: solid 1px $gray300;
    border-radius: 10px;
  }

  // Checkboxes, switches
  input[type="checkbox"].form-check-input:not([role="switch"]) {
    width: 12px;
    height: 12px;
    padding: 4px;
    border-radius: 2px;

    &:active,
    &:focus {
      box-shadow: none;
    }

    &::after {
      padding: 4px;
    }
  }

  input[role="switch"].form-check-input:checked:after,
  input[role="switch"]:checked:after {
    content: none;
  }

  &.edit-saved-search {
    #emailNotifications,
    #pushNotifications {
      box-shadow: none;
      width: 32px;

      &:focus {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ced4da'/%3e%3c/svg%3e");
      }
      &:checked {
        background-color: $yellow;
        border-color: $yellow;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ffffff'/%3e%3c/svg%3e");
      }
    }

    .input-group {
      border: #ced4da 1px solid;
      border-radius: 0.25rem;

      #what {
        z-index: 3;
        border: #fff;
        border-radius: 0.25rem;
        padding: 7px 12px 7px 40px;
      }

      .search-field-icon {
        color: #212529;
        position: absolute;
        left: 10px;
        top: 7px;
        z-index: 4;
      }
      .col-8 {
        position: relative;
        button.btn-clear {
          background-color: #fff;
          border: none;
          border-radius: 0.25rem;
          position: absolute;
          right: 0;
          top: 0;
          z-index: 4;
          height: 100%;
          margin-right: 1px;
        }
      }
    }
  }

  .checkbox-label {
    font-size: 12px;
  }

  .input-switch-label {
    @include media-breakpoint-down(sm) {
      max-width: 228px;
    }
  }

  .form-switch {
    @include media-breakpoint-down(md) {
      padding-left: 0px;
    }
  }

  // Buttons
  .button,
  .btn {
    padding: 12px 16px;
  }

  button {
    &:focus {
      box-shadow: none;
    }
    &:active {
      box-shadow: 0 0 0 0.25rem
        rgba(226, 0, 26, 0.25);
    }
  }

  // Facets
  .facetHeader {
    border-bottom: 1px solid $gray300;
  }

  .facetFilter {
    &.list-group-item {
      border: none;
    }

    h3 {
      border-style: none;
      font-size: 20px;
    }
  }

  .facetFilterWrapper {
    &.list-group-item {
      border: none;
    }

    h3 {
      font-family: "Montserrat", sans-serif;
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 24px;

      svg {
        transition: all 0.2s ease-in-out;
      }

      &.open svg {
        transform: rotate(180deg);
      }
    }

    input:hover {
      color: inherit;
    }
  }

  ul {
    list-style: none;
    font-size: 16px;
  }
}
