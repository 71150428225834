html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  padding: 0 !important;
  margin: 0;
  line-height: 1.5;
  font-family: "Open Sans", -apple-system,
    BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans",
    sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji";
}

h1 {
  text-transform: uppercase;
  strong {
    color: $primary;
  }
}

#jobnotfound h1 {
  text-transform: none;
}

.card {
  margin-bottom: 32px;
}

#nprogress {
  pointer-events: none;
  .bar {
    background: $primary;
    position: fixed;
    z-index: 1031;
    top: 0;
    left: 0;
    width: 100%;
    height: 2px;
  }

  /* Fancy blur effect */
  .peg {
    display: block;
    position: absolute;
    right: 0px;
    width: 100px;
    height: 100%;
    box-shadow: 0 0 10px $primary,
      0 0 5px $primary;
    opacity: 1;
    transform: rotate(3deg) translate(0px, -4px);
  }
}

label.required::after {
  content: "*";
  display: inline-block;
  margin-left: 2px;
}

h1 {
  word-break: break-word;
}

button.page-link:focus {
  box-shadow: none;
}

a.return {
  font-weight: 600;
  color: $primary;
  cursor: pointer;
  &:hover {
    color: $primary;
  }
  &:before {
    content: url("/icons/arrow-left.svg");
    margin-right: 20px;
  }
  &:active,
  &:focus {
    outline: none;
  }

  @include media-breakpoint-down(sm) {
    display: block;
    width: 100%;
    text-align: center;
  }
}

.list-group-item a {
  &:active,
  &:focus {
    outline: none;
  }
}

button.btn.btn-outline-secondary {
  color: #2f3336;
  border-color: #2f3336;
  &:hover {
    color: #fff;
    background-color: #2f3336;
  }
}

a.return {
  font-weight: 600;
  color: $primary;
  cursor: pointer;
  &:hover {
    color: $primary;
  }
  &:before {
    content: url("/icons/arrow-left.svg");
    margin-right: 20px;
  }
  &:active,
  &:focus {
    outline: none;
  }

  @include media-breakpoint-down(sm) {
    display: block;
    width: 100%;
    text-align: center;
  }
}

.list-group-item a {
  &:active,
  &:focus {
    outline: none;
  }
}

button.btn.btn-outline-secondary {
  color: #2f3336;
  border-color: #2f3336;
  &:hover {
    color: #fff;
    background-color: #2f3336;
  }
}

.instructions {
  font-size: 0.75rem;
  color: $mutedgray;
  font-weight: 200;
}

font-weight-600 {
  font-weight: 600;
}

.btn-yellow {
  @include button-variant(
    $yellow,
    darken($yellow, 7.5%),
    darken($yellow, 10%),
    lighten($yellow, 5%),
    lighten($yellow, 10%),
    darken($yellow, 30%)
  );
}

.btn-outline-yellow {
  @include button-outline-variant(
    $yellow,
    #222222,
    lighten($yellow, 5%),
    $yellow
  );
}

.embed-responsive-item {
  aspect-ratio: 16 / 9;
  width: 100%;
}

.h-lg-50 {
  @include media-breakpoint-up(lg) {
    min-height: 50%;
  }
}

.w-sm-auto {
  @include media-breakpoint-up(sm) {
    width: auto !important;
  }
}

.w-md-auto {
  @include media-breakpoint-up(md) {
    width: auto !important;
  }
}

.w-lg-auto {
  @include media-breakpoint-up(lg) {
    width: auto !important;
  }
}