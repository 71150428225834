.testimonialCard {
  @include media-breakpoint-down(md) {
    border: none;
  }
  .testimonialTile{
    .quote {
      font-size: 24px;
      font-weight: 700;
      @include media-breakpoint-down(sm) {
        font-size: 16px;
      }
    }

    .redSquare {
        width: 150px;
        height: 110px;
        background-color: #e2001e;
        position: absolute;
        bottom: -15px;
        right: -15px;
        @include media-breakpoint-down(sm) {
          width: 92px;
          height: 65px;
          bottom: -10px;
          right: -10px;
        }
      }
    .personInfo {
      color: #6C757D;
      text-transform: uppercase;
    }
  }
}

