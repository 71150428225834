#landing {
  h2 strong {
    color: $black;
  }

  img#header {
    max-width: 1920px;
    max-height: 450px;
    object-fit: cover;
  }

  margin: 0 auto;

  h2 {
    font-weight: 600;
    font-size: 1.4em;
  }

  article ul {
    list-style-type: none;
    padding-left: 1.8em;
    li {
      &:before {
        color: $primary;
        content: "\25A0";
        font-size: 10px;
        font-weight: 800;
        display: inline-block;
        margin-left: -1.8em;
        width: 1.8em;
        top: -0.3em;
      }
    }
    p {
      display: inline;
    }
  }

  .youtubeContainer {
    margin: 2rem 0;
  }

  .content-section.col-lg-6 .paragraph {
    h2 {
      text-align: center;
      u {
        padding-bottom: 10px;
        border-bottom: 1px solid $yellow;
        display: inline-block;
        text-decoration: none;
      }
    }
  }
}
