#werkenBijBanner {
    background-color: $gray800;
    height: 450px;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4) ), url('/images/headers/synergiejobs.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-blend-mode: darken;
    background-position: center center;
    color: #fff;
    cursor: pointer;
    @include media-breakpoint-down(sm) {
        height: 400px;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4) ), url('/images/headers/synergiejobs_mobile.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        background-blend-mode: darken;
        background-position: center center;
        .bannerText {
            padding: 20px;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
        }
    }
    h2 {
        font-size: 1.7rem;
        text-transform: none;
    }
}
