#applicationForm {
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.125);
  .card-body {
    background-color: $gray-100;
    padding: 2rem;
    background-color: #fff;
    // box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
    .react-select__placeholder {
      font-size: 14px;
    }
    label {
      &.text-muted {
        font-size: 14px;
        margin-bottom: 0;
      }
    }
    *:focus,
    *:active,
    .react-select__control--is-focused {
      outline: none !important;
      box-shadow: none !important;
    }
    input:-webkit-autofill.form-control.is-valid,
    .react-select__value-container--has-value {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") !important;
      border-color: #28a745;
      padding-right: calc(1.5em + 0.75rem);
      background-repeat: no-repeat;
      background-position: center right
        calc(0.375em + 0.1875rem);
      background-size: calc(0.75em + 0.375rem)
        calc(0.75em + 0.375rem);
    }

    input.form-control.form-control:not(input[type="file"]),
    .react-select__control {
      border: none;
      border-bottom: solid 2px $gray200;
      border-left-color: none;
      border-right-color: none;
      border-top-color: none;
      transition: border-color 0.2s ease-in;
      &:focus {
        outline: none;
        box-shadow: none;
      }
      &:focus {
        border-bottom-color: $gray500;
        + span.input-group-text {
          border-bottom-color: $gray500;
        }
      }
      &.is-valid {
        border-bottom-color: $green400;
        + span.input-group-text {
          border-bottom-color: $green400;
        }
      }
      &.is-invalid {
        border-bottom-color: $red400;
        + span.input-group-text {
          border-bottom-color: $red400;
        }
      }
    }
    span.input-group-text {
      border: none;
      border-bottom: solid 2px $gray200;
      border-left-color: none;
      border-right-color: none;
      border-top-color: none;
      transition: border-color 0.2s ease-in;
    }
    .react-select.valid > .react-select__control {
      border-bottom-color: $green400;
    }

    input.is-valid:-webkit-autofill {
      -webkit-animation-name: autofill-valid;
      -webkit-animation-fill-mode: both;
    }
    input.is-invalid:-webkit-autofill {
      -webkit-animation-name: autofill-invalid;
      -webkit-animation-fill-mode: both;
    }

    .error-message {
      color: #aeb7be;
      font-size: 0.8rem;
    }
  }
  @include media-breakpoint-up(md) {
    .form-control-file {
      display: inline-block;
      width: auto;
      margin-left: 20px;
    }
  }

  &.submitting {
    &::before {
      content: "";
      position: absolute;
      height: 4px;
      width: 100%;
      animation: loading 0.5s linear infinite;
      background-size: 50% auto;
      background-image: linear-gradient(
        to right,
        $red 0%,
        #ff9ea9 50%,
        $red 100%
      );
    }
  }

  @keyframes loading {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: 100% 100%;
    }
  }

  // fix for bootstrap validation icon not showing on autofill
  @-webkit-keyframes autofill-valid {
    to {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
      background-color: #fff;
    }
  }
  @-webkit-keyframes autofill-invalid {
    to {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23E9020B' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23d9534f' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
      background-color: #fff;
    }
  }

  #terms {
    input {
      display: inline-block;
    }
    p {
      display: inline;
    }
    p {
      margin-bottom: 0px;
    }
  }

  // Styling for react-phone-number-input package components
  .PhoneInput--disabled {
    background-color: #e9ecef;
    border-radius: 0.25rem;
    .PhoneInputCountrySelect {
      display: none;
    }
  }

  .PhoneInputInput {
    border: none;
    background-color: inherit;
  }

  .PhoneInput {
    border-radius: 0.25rem;
    padding: 0.315rem 0.75rem;
    border-bottom: solid 2px $gray200;
    transition: border-color 0.2s ease-in;
    &:focus-within {
      outline: none;
      box-shadow: none;
      border-bottom-color: $gray500;
    }
    &.submitting .PhoneInputCountrySelect {
      display: none;
    }
  }

  .is-valid.PhoneInput {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    border-bottom-color: $green400;
    padding-right: calc(1.5em + 0.75rem);
    background-repeat: no-repeat;
    background-position: center right
      calc(0.375em + 0.1875rem);
    background-size: calc(0.75em + 0.375rem)
      calc(0.75em + 0.375rem);
  }

  .is-invalid.PhoneInput {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
    border-bottom-color: $red400;
    padding-right: calc(1.5em + 0.75rem);
    background-repeat: no-repeat;
    background-position: center right
      calc(0.375em + 0.1875rem);
    background-size: calc(0.75em + 0.375rem)
      calc(0.75em + 0.375rem);
  }
}
