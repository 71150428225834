.ctaCards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  > .card {
    @include media-breakpoint-up(md) {
      width: 45%;
    }
    .card-footer {
      border-top: none;
      padding-top: 0;
      border-bottom: 1px solid
        rgba(0, 0, 0, 0.125);
    }
  }
}
