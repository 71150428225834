div.companyTile {
  img {
    max-width: 250px;
    border-bottom: 1px solid #dee2e6;
    @include media-breakpoint-up(lg) {
      border-bottom: none;
      border-right: 1px solid #dee2e6;
      width: 180px;
    }
  }
  .companyName {
    h2, h3 {
      font-size: 18px;
      letter-spacing: 1.1px;
    }
    svg {
      position: relative;
      height: 12px;
      width: 12px;
      display: inline-block;
      transition: all 0.2s ease-in-out;
      left: 0;
      .fillColor {
        fill: $primary;
      }
    }
    @include media-breakpoint-down(lg) {
      max-width: 250px;
    }
  }
  &:hover {
    a {
      text-decoration: none;
    }
    svg {
      left: 6px;
    }
  }
}
