#event {
  .iconContainer {
    width: 40px;
    vertical-align: top;
    display: inline-block;
  }
  a.return {
    font-weight: 600;
    color: $primary;
    cursor: pointer;
    &:hover {
      color: $primary;
    }
    &:before {
      content: url("/icons/arrow-left.svg");
      margin-right: 20px;
    }

    @include media-breakpoint-down(sm) {
      display: block;
      width: 100%;
      text-align: center;
    }
  }

  address {
    span,
    .iconContainer {
      display: inline-block;
    }
    span {
      width: calc(100% - 40px);
    }
  }

  time {
    > div {
      display: inline-block;
      max-width: 50%;
    }
  }

  aside {
    position: sticky;
    top: 20px;
    .indexJobSearchForm {
      top: auto;
    }
  }
}
