footer {
  overflow: hidden;
  position: relative;
  z-index: 0;

  a {
    color: $white;
    font-weight: 300;
  }

  a:hover {
    color: inherit;
  }

  li > a {
    color: #e8e9f3;
  }

  h2 {
    color: white;
    font-weight: 400;
    font-size: 1.2rem;
    @include media-breakpoint-down(md) {
      font-size: 16px;
      margin: 10px 0;
    }
  }

  h4 {
    font-size: 1rem;
  }

  ul {
    list-style: none;
    padding-left: 0;

    li {
      padding: 0.5rem 0;
      @include media-breakpoint-down(sm) {
        padding: 0.2rem 0;
      }
      @include media-breakpoint-down(md) {
        padding: 0.2rem 0;
      }

      a {
        @include media-breakpoint-down(sm) {
          margin-right: 0px;
        }
      }
    }
  }

  .upper-row {
    @include media-breakpoint-down(md) {
      flex-direction: column;
      justify-content: center;
      text-align: center;
      align-content: center;
    }
  }

  .footer-social-link {
    display: inline-block;
    width: 32px;
    text-align: center;
  }

  .pageFooterTopWrapper {
    background-color: #2f3336;
    color: #ffffff;
  }

  .pageFooterBottomWrapper {
    background-color: #2f3336;
    color: #fdfdff;
  }

  .footer-divider {
    background-color: #e8e9f3;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  ul#footer-legals {
    font-size: 12px;
    text-transform: lowercase;
    a {
      color: $gray400;
    }
  }

  .footer-language {
    background-color: #2f3336;
    .dropdown {
      position: relative;
      flex-direction: column;

      button {
        @include media-breakpoint-down(lg) {
          min-width: 200px;
        }
        background-color: #2f3336;
        border: none;
        &:hover,
        &:active,
        &:focus {
          background-color: #2f3336;
          box-shadow: none;
          border: none;
        }
      }

      .dropdown-menu {
        width: 100%;
        box-shadow: 0px 0px 5px 0px
        rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        background-color: white;

        @include media-breakpoint-up(lg) {
          min-width: 240px;
        }

        .dropdown-item {
          font-size: 14px;
          font-weight: 400;
          letter-spacing: 0.25px;
          &:hover,
          &:focus,
          &:active {
            background-color: $gray100;
          }
        }
      }
    }
  }
}
