#fileUploadButton {
  .file-title {
    color: #aeb7be;
    font-size: 0.9em;
  }

  .hidden {
    display: none;
    opacity: 0;
  }
}

.fileUploadIcon {
  color: $mutedgray;
  &.success {
    color: $accent;
  }
}