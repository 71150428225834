#candidateWhy {
  h1 {
    line-height: 1.4em;
  }

  #footer {
    margin-top: 0;
    z-index: 400;
    position: relative;
  }
  p {
    max-width: 700px;
  }

  button.xs-large.cta {
    @include media-breakpoint-down(sm) {
      text-align: left;
      width: 100%;
      > svg {
        bottom: 12px;
      }
    }
  }
  .bigIcon {
    @include media-breakpoint-down(md) {
      width: 150px;
      height: 150px;
    }
  }

  button.cta.small {
    @include media-breakpoint-up(xl) {
      font-size: 12px;
    }
  }

  ul.bullets {
    list-style: none;
    padding: 0;
    li {
      min-width: 240px;
      padding-left: 1.8em;
      &:before {
        position: relative;
        color: $primary;
        content: "\25A0";
        font-size: 10px;
        font-weight: 800;
        display: inline-block;
        margin-left: -1.8em;
        width: 1.8em;
        top: -0.2em;
      }
      p {
        display: inline;
      }
    }
  }

  section#firstJob {
    background: linear-gradient(
        rgba(255, 255, 255, 0.47),
        rgba(255, 255, 255, 0.47)
      ),
      url("/header_my_first_job_3000.jpg")
        no-repeat center center fixed;
    background-size: cover;
    min-height: 500px;
    height: 500px;
    display: flex;
    align-items: center;
    @include media-breakpoint-down(sm) {
      min-height: 350px;
    }
    h2 {
      font-size: 22px;
      text-transform: uppercase;
      font-weight: 600;
    }
  }
}
