#jobsearch-saved-alert {
  border-bottom: 3px solid $green900 !important;
  span:nth-of-type(1) {
    color: $green900;
    font-size: 14px;
  }
  span:nth-of-type(2) {
    font-size: 13px;
  }
  button.btn-close {
    top: 10%;
    filter: invert(27%) sepia(29%) saturate(712%)
      hue-rotate(100deg) brightness(94%)
      contrast(94%);
  }
}
