.jobTeaser {
  border-color: $gray300;
  color: #2f3336;
  text-align: start;
  width: 100%;
  position: relative;
  word-wrap: break-word;

  h2 {
    text-transform: none;
  }

  h4 {
    font-weight: 600;
    font-size: 20px;
  }

  &:focus,
  &:active,
  &:hover {
    box-shadow: none;
  }

  .jobteaser-description,
  .jobteaser-facets {
    font-size: 14px;
  }
  svg.chevron-right {
    position: relative;
    margin-left: auto;
    right: 12px;
    transition: all 0.2s ease-in-out;
  }
  a:hover {
    svg.chevron-right {
      right: 8px;
    }
  }
  .domain-regime {
    > span:nth-of-type(2):before {
      content: "|";
      margin-left: 8px;
      margin-right: 8px;
    }
  }

  .jobteaser-actions {
    position: relative;
  }

  .thumbs-button {
    position: relative;
    width: 24px;
    height: 24px;

    * {
      position: absolute;
      left: 0;
      right: 0;
      width: 20px;
    }
  }

  .contour-inactive {
    transition: all 0.3s ease-in;
  }

  .contour-approved {
    transform-origin: left center;
    animation: contour 0.5s;
  }

  .contour-rejected {
    transform-origin: right center;
    animation: contour 0.5s;
  }

  .fill-inactive {
    opacity: 0;
    transition: all 0.3s ease-in;
  }

  .fill-active {
    opacity: 1;
    color: $accent;
    transform-origin: center;
    animation: fill 0.7s;
  }

  @keyframes bounce {
    0% {
      transform: scale(0.5);
    }
    30% {
      transform: scale(1.2);
    }
    50% {
      transform: scale(1);
    }
    80% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }

  @keyframes contour {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(-20deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }

  @keyframes fill {
    0% {
      transform: scale(0) rotate(0deg);
    }
    30% {
      transform: scale(0) rotate(-12deg);
    }
    50% {
      transform: scale(0.2) rotate(-20deg);
    }
    100% {
      transform: scale(1) rotate(0deg);
    }
  }
}
