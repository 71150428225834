#blog {
  a.blogFacet {
    position: relative;
    padding: 4px 8px;
    border: solid 1px;
    border-radius: 5px;
    color: $gray800;
    border-color: $gray800;
    margin-bottom: 10px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 700;
    flex-grow: 1;
    cursor: pointer;
    &.active {
      background-color: $gray800;
      color: #fff;
    }
    &.active {
      padding-right: 30px;
      &:after {
        content: "x";
        position: absolute;
        right: 10px;
        font-weight: 200;
        display: inline-block;
      }
    }
    &.category {
      &.story {
        color: $red;
        border-color: $red;
        &.active {
          background-color: $red;
          color: #fff;
        }
      }
      &.tips {
        color: $yellow;
        border-color: $yellow;
        &.active {
          background-color: $yellow;
          color: #fff;
        }
      }
      &.news {
        color: $blue;
        border-color: $blue;
        &.active {
          background-color: $blue;
          color: #fff;
        }
      }
      &.event {
        color: $red;
        border-color: $red;
        &.active {
          background-color: $red;
          color: #fff;
        }
      }
      &.construct {
        color: $yellow;
        border-color: $yellow;
        &.active {
          background-color: $yellow;
          color: #fff;
        }
      }
      &.fact {
        color: $accent;
        border-color: $accent;
        &.active {
          background-color: $accent;
          color: #fff;
        }
      }
    }
  }
}
