.mySynergie {
  .jobTeaser {
    font-family: "Open Sans", sans-serif;
    border-radius: 10px !important;
  }

  .mysynergie-alert {
    display: flex;
    align-items: center;

    button {
      top: auto;

      &:active {
        box-shadow: none;
      }
    }

    p {
      font-size: 12px;
    }
  }

  .breadcrumb .breadcrumb-item:last-child {
    color: $primary;
  }

  hr {
    color: $gray300;
    opacity: 1;
  }

  .sort-select {
    width: auto;
    min-width: 150px;
    border-color: #e8e9f3;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1.25px;

    &:focus {
      box-shadow: none;
    }

    &.form-select {
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.0002 5.83L15.1702 9L16.5802 7.59L12.0002 3L7.41016 7.59L8.83016 9L12.0002 5.83ZM12.0002 18.17L8.83016 15L7.42016 16.41L12.0002 21L16.5902 16.41L15.1702 15L12.0002 18.17Z' fill='black'/%3E%3C/svg%3E%0A");
      background-size: 20px auto, 100%;
    }
  }

  .header-icon-container {
    background-color: $gray800;
    border-radius: 50%;
    color: $gray100;
    height: 40px;
    width: 40px;
  }

  .header-icon {
    display: table-cell;
    height: 27px;
    min-width: 27px;
    text-align: center;
    vertical-align: middle;
  }

  .delete-right-radius {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    > input,
    > select {
      border-top-right-radius: 0px !important;
      border-bottom-right-radius: 0px !important;
    }
  }

  .delete-left-radius {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    > input,
    > select {
      border-top-left-radius: 0px !important;
      border-bottom-left-radius: 0px !important;
    }
  }
}

ul.unstyled {
  list-style: none;
}

.text-initial {
  text-transform: initial;
}

.breadcrumb-item,
.back-button,
.user-email {
  font-size: 12px;
  letter-spacing: 1.5px;

  &:focus {
    box-shadow: none;
  }
}
