.blogpostTeaser.card {
  @include media-breakpoint-up(lg) {
    &:not(.wide) {
      height: 100%;
      //fix for IE
      min-height: 1px;

      .imageContainer {
        //fix for IE
        min-height: 1px;
      }
    }
  }
  h3 {
    font-size: 18px;
    font-weight: 600;
  }
  img {
    width: 100%;
    height: auto;
  }
  .card-body {
    padding: 10px 20px;
    min-height: 180px;
    //fix for IE
    min-width: 1px;
  }
  .card-text {
    margin-bottom: 0;
    padding-bottom: 1rem;
    border-bottom: solid 1px $primary;
    min-width: 1px;
  }
  a {
    display: block;
    color: $gray600;
    font-size: 12px;
  }
  .arrowRightFill {
    fill: $gray600;
  }
  span.category {
    font-weight: 600;
    font-size: 14px;
    text-transform: uppercase;
    &:before {
      content: "";
      display: inline-block;
      position: relative;
      height: 5px;
      width: 20px;
      margin-right: 6px;
      background-color: $gray800;
    }
  }

  &.wide {
    display: flex;
    @include media-breakpoint-up(md) {
      flex-direction: row;
    }
    .col-lg-4 {
      padding: 0;
    }
    img {
      width: 100%;
      height: 220px;
      min-height: 100%;
      object-fit: cover;
    }
  }

  .blogCreated {
    font-size: 14px;
    color: $gray600;
  }
  &.event, &.story {
    span.category {
      color: $red;
      &:before {
        background-color: $red;
      }
    }
    .card-text {
      border-bottom: solid 1px $red;
    }
  }
  &.tips {
    span.category {
      color: $yellow;
      &:before {
        background-color: $yellow;
      }
    }
    .card-text {
      border-bottom: solid 1px $yellow;
    }
  }

  &.news {
    span.category {
      color: $blue;
      &:before {
        background-color: $blue;
      }
    }
    .card-text {
      border-bottom: solid 1px $blue;
    }
  }

  &.fact {
    span.category {
      color: $accent;
      &:before {
        background-color: $accent;
      }
    }
    .card-text {
      border-bottom: solid 1px $accent;
    }
  }
  &.construct {
    span.category {
      color: $yellow;
      &:before {
        background-color: $yellow;
      }
    }
    .card-text {
      border-bottom: solid 1px $yellow;
    }
  }
}
