.mySynergie {
  .button,
  .btn {
    border-radius: 5px;
    border-style: solid;
    border-width: 1px;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1.25px;
    text-align: center;
    &:hover,
    &:focus,
    &:active {
      box-shadow: none;
    }
    &.camera-icon {
      border-radius: 50%;
    }
  }

  .button-outline-primary {
    border-color: $primary;
    color: $primary;
    &:hover,
    &:focus,
    &:active {
      background-color: $primary;
      color: white;
    }
  }

  .button-blue {
    background-color: $blue;
    border-color: $blue;
    color: white;
    transition: border-color 0.3s;
    &:hover,
    &:focus,
    &:active {
      background-color: white;
      color: $blue;
    }
  }

  .button-outline-blue {
    background-color: white;
    border-color: $gray300;
    color: $blue;
    &:hover,
    &:focus,
    &:active {
      color: $blue;
      border-color: $gray900;
    }
  }

  .button-mysynergie-secondary {
    background-color: inherit;
    border-color: $gray300;
    color: inherit;
    box-shadow: none;
    &:focus,
    &:hover,
    &:active {
      box-shadow: none;
    }
    &:hover,
    &:active {
      border-color: $gray900;
    }
  }

  .button-mysynergie-icon {
    background-color: inherit;
    color: inherit;
    border: none;

    &:hover,
    &:focus,
    &:active {
      background-color: inherit;
      box-shadow: none;
    }

    &.light {
      color: $gray500;
      &:hover,
      &:focus,
      &:active {
        color: $gray500;
      }
    }

    &.blue {
      color: $blue;
      &:hover,
      &:focus,
      &:active {
        color: $blue;
      }
    }

    &.black {
      color: black;
      &:hover,
      &:focus,
      &:active {
        color: black;
      }
    }
  }

  .layout-buttons {
    border: none;

    > * {
      color: $gray500;
    }

    .active {
      color: #000;
    }

    &:hover,
    &:focus,
    &:active {
      box-shadow: none;
    }
  }
}

button.like-button {
  height: 38px;

  &.iconOnly {
    height: 24px;
    width: 24px;
  }

  svg {
    position: absolute;
    left: 0;
    right: 0;
    transition: all 0.3s ease-in;
    opacity: 0;
    &.show {
      opacity: 1;
    }
    &.bounce {
      animation: bounce 1s;
    }
  }
  &:hover,
  &:focus,
  &:active {
    box-shadow: none;
    border-style: none;
  }
  border-radius: 4px;
  border-style: none;
}

button#share-button {
  background-color: white;
  border-color: $gray300;
  color: $gray900;

  > svg {
    color: $gray500;
  }

  &:hover,
  &:focus,
  &:active {
    background-color: white;
    border-color: $gray300;
    box-shadow: none;
    color: inherit;
  }
}
