.job {
  a.return {
    font-weight: 600;
    color: $primary;
    cursor: pointer;
    &:hover {
      color: $primary;
    }
    &:before {
      content: url("/icons/arrow-left.svg");
      margin-right: 20px;
    }

    @include media-breakpoint-down(sm) {
      display: block;
      width: 100%;
      text-align: center;
    }
  }
  #jobSpecs {
    padding: 20px;
    border: solid 1px $border-color;
    @include media-breakpoint-up(md) {
      .col-md-6:nth-of-type(1) {
        border-right: solid 1px #dfdfdf;
      }
    }
    .jobSpecList {
      list-style-type: none;
      padding: 0;
      margin-bottom: 0;
      position: relative;
      box-sizing: border-box;
      .jobLabel {
        text-transform: uppercase;
        color: $gray-600;
        letter-spacing: 0.1em;
        margin-right: 10px;
      }
    }
  }
  #jobDescription {
    ul {
      list-style: none;
      padding: 0;
    }

    ul li {
      padding-left: 1.8em;
      &:before {
        color: $primary;
        content: "\25A0";
        font-size: 10px;
        font-weight: 800;
        display: inline-block;
        margin-left: -1.8em;
        width: 1.8em;
        top: -0.3em;
      }
      p {
        display: inline;
      }
    }
  }

  .sticky {
    position: sticky;
    z-index: 30;
    top: 5px;
    display: block;
    // don't show content above the sticky item
    &:after {
      content: "";
      position: absolute;
      background-color: #fff;
      top: -5px;
      left: 0;
      right: 0;
      height: 5px;
    }
  }

  @include media-breakpoint-down(lg) {
    .card.agencyTeaser {
      display: flex;
      margin-bottom: 20px;
    }
    .cta.red.big#desktop {
      display: none;
    }
    button.cta.noArrow {
      width: 100%;
    }
  }

  #share-dropdown:hover #share-menu {
    display: block;
  }

  #share-menu {
    border-radius: 10px;
    border-width: 0;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);

    .dropdown-item:hover {
      background-color: $gray100;
    }

    // Implement Bootstrap values to prevent dropdown menu from moving around
    position: absolute;
    inset: 0px 0px auto auto;
    transform: translate3d(0px, 37.6px, 0px);
  }
}
