#about {
  #footer {
    margin-top: 0;
    z-index: 400;
    position: relative;
  }
  p {
    max-width: 700px;
  }
  .bigIcon {
    @include media-breakpoint-down(md) {
      width: 150px;
      height: 150px;
      min-width: 1px;
    }
  }

  .nav-tabs {
    a {
      cursor: pointer;
      transition: background-color 0.3s
        ease-in-out;
      border-color: #2f3336 !important;
      filter: grayscale(1);
      &.active {
        color: #fff;
        background-color: #2f3336;
        img {
          filter: invert(100%);
        }
      }
    }
  }
  .tab-content .card-body {
    margin-bottom: 0;
  }

  button.cta.small {
    @include media-breakpoint-up(xl) {
      font-size: 12px;
    }
  }

  #main-cta {
    z-index: 20;
    position: absolute;
    @include media-breakpoint-up(lg) {
      position: fixed;
      bottom: 20px;
      top: auto;
    }
    @include media-breakpoint-down(lg) {
      display: block;
      top: auto;
      bottom: -20px;
      width: 300px;
      left: calc(50% - 150px);
      &.fixed {
        top: auto;
        position: fixed;
        bottom: 20px;
      }
      button {
        width: 100%;
      }
    }
    button {
      font-weight: 600;
      border-radius: 5px;
    }
  }
  .cta-about {
    svg {
      bottom: 11px;
    }
  }
}
