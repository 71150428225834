.companiesPage {
  a {
    text-decoration: none;
  }
  .companiesCompanyTeaser {
    border: none;
    .logoContainer {
      padding: 16px;
      .logo {
        padding-bottom: 50%;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }
    }
  }
}
