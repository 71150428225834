.agencyPage {
  address {
    color: $gray-600;
    line-height: 1.8;
  }
  @include media-breakpoint-down(md) {
    #agencyTitle {
      order: 1;
      margin-bottom: 20px;
    }
    .mapsContainer {
      order: 2;
      min-height: 400px;
    }
  }

  // Necessary to align with .card 32px bottom
  iframe {
    @include media-breakpoint-up(lg) {
      padding-bottom: 32px;
    }
  }
}
