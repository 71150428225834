.agenciesPage {
  form.search {
    background-color: rgb(245, 245, 245);
  }

  .map-container {
    min-height: 475px !important;
  }

  .gm-ui-hover-effect {
    margin: 10px !important;
  }
}
