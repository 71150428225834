.filterSummary {
  align-items: start;
  label,  #filter-reset {
    min-width: 130px;
  }
  li.activeFacet button, button#filter-reset {
    font-size: 1rem;
    &:hover {
      background-color: $gray100;
    }
  }
  @include media-breakpoint-down(md) {
    button#filter-reset {
      display: none;
    }
    border-bottom: 1px solid $border-color;
  }
}
