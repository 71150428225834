#withoutBgHeader {
  .pageHeaderContainer {
    position: relative;
    margin: 0 auto;
    min-height: 300px;
    background-color: $light;
    background-repeat: no-repeat;
    background-size: cover;
    color: #000;

    &.noBgColor {
      background-color: white;
    }

    .textContainer {
      max-width: 400px;
      hr {
        opacity: 1;
        color: white
      }
      &.withoutBg {
        hr {
          color: $primary;
        }
      }
    }
  }
}