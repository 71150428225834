input[type="checkbox"].form-check-input,
input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  background-color: white;
  border: 2px solid $gray-400;
  padding: 7px;
  margin-right: 15px;
  position: relative;

  &:focus {
    outline: none;
  }

  &:checked:active {
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05),
      inset 0px 1px 3px rgba(0, 0, 0, 0.1);
  }

  &:checked {
    background-color: $red;
    border: 2px solid $red;
  }

  &:checked:after {
    content: url("/icons/check-solid.svg");
    width: 13px;
    position: absolute;
    top: -5px;
    left: 1px;
    color: black;
    display: block;
  }
}

.thanks .pageHeaderImage {
  max-height: 300px;
}

.alert.sticky {
  position: sticky;
  top: 15px;
  z-index: 100;
  &:before {
    content: "";
    top: -16px;
    left: -10%;
    position: absolute;
    height: 15px;
    width: 120%;
    background-color: #fff;
  }
}

h2 {
  text-transform: uppercase;
  strong {
    color: $primary;
  }
}

b,
strong {
  font-weight: 600;
}
