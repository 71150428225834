.indexJobSearchForm {
  padding: 24px;
  position: relative;
  background-color: $primary;
  z-index: 1;

  h1,
  h3 {
    color: white;
    margin-bottom: 20px;
    strong {
      font-weight: bold;
    }
  }

  h1 {
    font-weight: 300;
    font-size: 27px;
    text-transform: uppercase;
    @include media-breakpoint-down(md) {
      font-size: 22px;
    }
    strong {
      color: #ffffff;
      font-weight: 600;
    }
  }

  label {
    text-transform: capitalize;
  }

  form {
    .field-container {
      .input-group {
        border: #fff;
        border-radius: 0.25rem;

        #what {
          border: #fff;
          border-radius: 0.25rem;
          padding: 7px 12px 7px 40px;
        }

        .search-field-icon {
          color: #212529;
          position: absolute;
          left: 10px;
          top: 7px;
          z-index: 4;
        }

        button.btn-clear {
          background-color: #fff;
          border: none;
          border-radius: 0.25rem;
          position: absolute;
          right: 0;
          z-index: 4;
          &:hover{
            color: $gray900;
          }
          &.btn-clear-location {
            border-radius: 0;
            margin-right: 1px;
            top: 0;
            right: 30%;
          }
        }

        .location-select,
        .radius-select {
          input {
            margin-bottom: 0;
          }
        }

        .location-select {
          position: relative;
          width: 70%;
        }

        .radius-select {
          width: 30%;
        }
      }

      width: auto;
      @include media-breakpoint-up(lg) {
        width: 35%;
      }
    }

    .field-container,
    .top-level-button {
      margin: 0 20px 20px 20px !important;
      &.field-container:first-of-type {
        margin: 0px 20px 20px 20px !important;
      }
      &.top-level-button {
        margin: 0px 20px !important;
      }

      @include media-breakpoint-up(lg) {
        margin: 10px !important;
        &.field-container:first-of-type {
          margin: 10px 10px 10px 0px !important;
        }
        &.top-level-button {
          margin: 10px 0px 10px 10px !important;
        }
      }
    }

    .top-level-button {
      flex-grow: 1;
      svg {
        margin-right: 10px;
        position: relative;
        bottom: 2px;
        @include media-breakpoint-only(md) {
          width: 16px;
          margin-right: 4px;
        }
      }
    }
  }

  &#side {
    margin-bottom: 20px;
    position: sticky;
    top: 20px;
    form {
      flex-direction: column !important;
      .field-container {
        margin: 0 0 20px 0 !important;
        width: auto;
        .location-select {
          position: relative;
        }
      }
      .top-level-button {
        margin: 0 !important;
      }
    }
    h1 {
      text-align: center !important;
    }
  }
}
